
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

:root {
  --main-color: #5ea3d0;
  --secondary-color: white;
  --main-text-color: #3e5869;
  --secondary-text-color: #b0c7d6;
  --send-message-form: #F5F5F5;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.status-bar {
  font-family: "Fira Code";
  height: 40px;
  border: 1px solid #999999;
  width: 100%;
  vertical-align: bottom;
}

.status-bar-element {
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 40px;
  display: inline-block;
}

.status-bar-element.status-bar-chapter {
  padding-right: 0;
  max-width: 39em;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.editor-main {
  height: 100vh;
  width: 50em;
  left: 8em;
  position: fixed;
  display: inline-block;
}

.left-sidepanel {
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  height:100vh;
  width:8em;
  background-color: #f8f8f8;
  border-right: 1px solid lightgrey;
}

.discussion-sidepanel {
  top: 0;
  right: 0;
  position: fixed;
  overflow: auto;
  height:100vh;
  width: calc(100vw - 58em);
  background-color: #f8f8f8;
  border-left: 1px solid lightgrey;
}

.editor {
  overflow-y: scroll;
  padding-right: 25px;
  padding-left: 15px;
  max-height: calc(100vh - 40px);
  /*border-left: 1px solid lightgrey;*/
}


/* group */
.editor-line {
  padding-left: 10px;
  border-left: 7px solid transparent;
  position: relative;
  /*border-radius: 3px;*/
}

.subject-line {
  background-color: #1a9bcb33;
}

.active-line {
  border-left: 7px solid #F7C70A;
  /*border-radius: 3px;*/
}

.verbatim-active-line {
  border-left: 7px solid red;
  /*border-radius: 3px;*/
}

.has-discussion::after {
  content:"";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='rgb(248,232,150)' d='M 21.9891,3.99805C 21.9891,2.89404 21.1031,1.99805 19.9991,1.99805L 3.99913,1.99805C 2.89512,1.99805 1.99913,2.89404 1.99913,3.99805L 1.99913,15.998C 1.99913,17.1021 2.89512,17.998 3.99913,17.998L 17.9991,17.998L 21.9991,21.998L 21.9891,3.99805 Z '%3E%3C/path%3E%3C/svg%3E");
  display:inline;
  width:30px;
  height:20px;
  position: absolute;
  right: -20px;
  top: 0;
  /*margin:10px 5px 0 10px;*/
}

/*.has-discussion {*/
/*  border-right: 17px solid #1a9bcb88;*/
/*}*/

/* END group */

.translation {
  padding-top: 7px;
  padding-bottom: 15px;
  padding-left: 1em;
  user-select: text;
  font-family: "Source Serif Pro";
  font-size: 1.1em;
  /*font-style: italic;*/
  color: #777777;
  /*color: #999999;*/
}

.markdown-editor {
  user-select: none;
  outline: 0 solid transparent;
  display: inline-block;
  white-space: normal;
}

.markdown-editor.in-edit {
  min-width: 3px;
  user-select: auto;
  border-bottom: 2px solid #1a9bcb;
}

.sentence {
  user-select: none;
  font-family: "Fira Code";
  margin-bottom: 1.2em;
  font-size: .9em;
}

.sentence-editor {
  /*display: inline-block;*/
}

.paragraph-break {
  color: black;
  font-family: "Fira Code";
  margin-bottom: 1em;
}

.chapter-complete {
  color: black;
  font-family: "Fira Code";
  margin-bottom: 1em;
}

.silence-marker {
  color: black;
  font-family: "Fira Code";
  margin-bottom: 1em;
}

.speaker-label {
  color: #78909c;
  font-family: "Fira Code";
  font-size: .9em;
  margin-top: .25em;
}

.speaker-label .markdown-editor {
  text-decoration: underline #78909c;
}

.passage-hint {
  /*color: #1a9bcb;*/
  font-weight: bold;
  font-family: "Fira Code";
  margin-top: 2em;
  margin-bottom: .5em;
  border-bottom: 2px solid #E6E1E8;
}

.chapter-title{
  color: black;
  font-family: "Fira Code";
  font-size: 1.5em;
  margin-top: 1em;
}

.chapter-summary {
  font-family: "Fira Code";
  font-size: 1.1em;
  color: #777777;
  font-weight: bold;
  margin-top: 1em;
}

.cultural-note {
  font-family: "Fira Code";
  font-weight: bold;
  margin-top: .5em;
  margin-bottom: .5em;
}

.metadata-block {
  font-family: "Fira Code";
  font-size: .9em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.metadata-block.asset-links {
  margin-bottom: 3em;
}

.metadata-block .markdown-editor.in-edit {
  border-bottom: 2px solid transparent;
}

.metadata-block .markdown-editor.not-in-edit {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metadata-url::before {
    content:'/**! METADATA URL';
    display:block;
}

.notes::before {
  content:'/**! NOTES';
  display:block;
}

.metadata::before {
  content:'/**! METADATA';
  display:block;
}

.cast::before {
  content:'/**! CAST';
  display:block;
}

.asset-links::before {
  content:'/**! ASSET-LINKS';
  display:block;
}

.metadata-block::after {
  content:'*/';
  display:block;
}

.alerts {
  height: 30px;
  width: 100%;
  border: 1px solid lightgrey;
  border-top: 3px solid lightgrey;
  margin-bottom: 10px;
}

.message {
  margin: 15px 0;
}

.message div:nth-child(1) {
  font-size: 11px;
  color: var(--main-text-color);
  opacity: 0.9;
  margin-bottom: 6px;
}

.message div:nth-child(2) {
  background: var(--main-color);
  color: var(--secondary-color);
  display: inline;
  padding: 4px 8px;
  border-radius: 8px;
}

.message-list {
  box-sizing: border-box;
  padding: 0 0 80px 6px;
  margin: 0;
  width: 100%;
  height: 100%;
  /*font-family: "Fira Code";*/
  font-size: .9em;
  overflow: scroll;
  background: white;
}

.message-form-container {
}

.send-message-form {
  background: var(--send-message-form);
  display: flex;
  box-sizing: border-box;
  height: 60px;
  border: 1px solid #78909c;
}

.send-message-form input {
  width: 100%;
  padding: 15px 10px;
  border: none;
  margin: 0;
  background: var(--send-message-form);
  font-weight: 200;
}

.send-message-form input:focus {
  outline-width: 0;
}

.send-message-form input::placeholder {
  color: black;
}

.discussions-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  /*font-family: "Fira Code";*/
  font-family: Nunito;
  border: 1px solid #999999;
  /*color: (--secondary-color);*/
  /*background: var(--main-color);*/
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
}


.speech-bubble {
  filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15));
  margin: 1rem;
  margin-bottom: 35px;
  padding: .5rem .5rem;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  /*font-family: 'Source Sans Pro', sans-serif;*/
  font-size: .9em;
  font-weight: 400;
  background: #f8e896;
  color: black;
}

.speech-bubble::before {
  border: 8px solid transparent;
  border-top: 8px solid #f8e896;
  border-bottom: 0;
  height: 0;
  width: 0;
  border-top-width: 16px;
  content: '';
  display: block;
  position: absolute;
  left: 3rem;
  bottom: -16px;
  transform-origin: center;
  transform: rotate(90deg) skew(-25deg) translateY(16.66667px);
}

.speech-bubble cite {
  filter: none;
  position: absolute;
  bottom: -1.5rem;
  left: 4rem;
  font-family: "Fira Code";
  font-size: .75rem;
  font-style: normal;
  font-weight: 300;
  /*letter-spacing: 0.5px;*/
  color: #999999;
}

/*export const GroupCore = elem("div")({*/
/*  display: "inline-block",*/
/*  flexWrap: "wrap",*/
/*  alignItems: "center",*/
/*  borderBottom: "2px solid transparent",*/
/*  background: "transparent",*/
/*  cursor: "default"*/
/*});*/
.word {
  user-select: none;
  border-bottom: 1px solid transparent;
  line-height: 1.5;
}

.vocab {
  border-bottom: 1px solid #4ED152;
  /*border-bottom: 1px solid #91de4b;*/
  /*border-bottom: 1px solid darkgray;*/
}

.tricky {
  /*border-bottom: 1px solid #E5E5E5;*/
  border-bottom: 1px solid darkgray;
  /*background-color: #f8e896;*/
  /*background-color: #fffeed;*/
}

/*.tricky.unresolved {*/
/*  background-color: #E6E1E8;*/
/*  border-bottom: 1px solid transparent;*/
/*}*/

.vocab.unfilled {
  background-color: palegreen;
  border-bottom: 1px solid transparent;
}

.vocab.unfilled.selected {
  border-bottom: 2px solid forestgreen;
  /*border-bottom: 1px solid dodgerblue;*/
}

.sic {
  border-bottom: 1px solid #F5832B;
}

.selected {
  background-color: #bae5ff;
}

.word-range-select {
  user-select: none;
}

#tooltip {
  font-family: "Fira Code";
  background: #fffbbf;
  color: black;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 15px;
  border-radius: 4px;
  display: none;
  border: 1px solid black;
}

#tooltip[data-show] {
  display: block;
}


/*const componentTypes = {*/
/*normal: StyledGroup,*/
/*vocab: styled(StyledGroup)({*/
/*  borderBottom: "2px solid #91de4b"*/
/*}),*/
/*tricky: styled(StyledGroup)({*/
/*  fontStyle: "cursive",*/
/*  backgroundColor: "#fff8a6",*/
/*  borderBottom: "2px solid #6bbdca"*/
/*}),*/
/*sic: styled(StyledGroup)({*/
/*  borderBottom: "2px solid #ffa100"*/
/*})*/

.GridScrollWrapper {
  position: relative;
  transition: height 0.3s;
  border-bottom: 1px solid #e9e9e9;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.GridContainer {
  padding-left: 3px;
  position: relative;
  height: 100%;
}

.GridScrollDummy {
  position: relative;
  min-width: 100%;
}

.GridPlaceholder {
  text-align: center;
  height: 53px;
  background: #fff;
  padding: 15px;
}

.VirtualizedRows {
  width: 100%;
  /*font-family: "Roboto";*/
  font-family: "Nunito";
  /*font-family: "Roboto Slab";*/
  /*font-weight: 200;*/
  user-select: none;
  border-collapse: collapse;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

.GridRow {
  padding-left: 5px;
  border-left: 7px solid transparent;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  /*border-left: 7px solid #33b5e5;*/
  /*border: 2px solid transparent;*/
}

.GridRowActive {
  border-left: 7px solid #33b5e5;
  /*border: 2px solid #33b5e5;*/
}

.work-group-thread {
  font-size: .9em;
  font-family: "Nunito";
  /*font-family: "Roboto Slab";*/
  /*font-weight: 200;*/
}

.thread-item {
  filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15));
  /*background-color: #f8e896;*/
  background-color: #fff5bf;
  margin: .75rem 0;
  padding: 5px;
}

.thread-item-focused {
  background-color: #ffed8f;
  /*background-color: #f8e896;*/
  /*background-color: #FFDF5E;*/
  /*border: 1px solid red;*/
}

.thread-item-selected {
  border: 1.5px solid #33b5e5;
  border-bottom: 2px solid #33b5e5;
  /*border: 2px solid dodgerblue;*/
}

.thread-item-post-info {
  margin-top: 4px;
  border-top: 1px solid darkgray;
  font-size: .8em;
}

.add-suggestion-form .md-form {
  margin: .25rem 0;
}

.add-suggestion-form .md-form label.active {
  font-size: inherit;
}

.add-suggestion-form .form-control {
  font-size: inherit;
}

.work-group-thread .btn.btn-sm {
  margin: 0;
  margin-right: .5rem;
  padding: .3rem 1rem;
}

.word-range-select {
  border-bottom: 1px solid darkgray;
  margin-bottom: .5rem;
}

.word-group-thread-container {
  /*border-top: 1px solid darkgray;*/
  border-bottom: 1px solid darkgray;
  margin: .5rem 0;
  padding: 0rem 0;
}

.word-group-comment-area {
  margin-top: 1rem;
}


.wg-sheet {
  height:37em;
}

.wg-sheet-right-sidepanel {
  border: 1px solid gray;
  border-left: 1px solid transparent;
  overflow-y: auto;
  padding: 1em;
  width:30em;
  height: 100%;
  float:right;
}

.wg-sheet-left-sidepanel {
  width:calc(100% - 30em);
  height: 100%;
  outline: none;
  border: 1px solid gray;
}

.wg-sheet-filters {
  font-family: "Nunito";
  height:7em;
  border-bottom: 1px solid lightgrey;
  font-weight: 400;
}

.wg-sheet-filters label {
  margin: .5em;
  display:block;
}

.wg-filter-column {
  vertical-align:top;
  display: inline-block;
  margin-right: 1.5em;
  /*width: 25%*/
}

.wg-sheet-left-bottom{
  height:calc(100% - 7em);
}

.wg-sheet-tasks {
  height:100%;
  /*height:calc(100% - 5em);*/
}

.wg-thread-translation {
  /*font-family: "Roboto Slab";*/
  /*font-weight: 200;*/
  font-family: "Nunito";
  /*padding-left: .5em;*/
  /*padding-top: .5em;*/
  /*height: 5em;*/
  width: 100%;
  /*overflow-y: auto;*/
  /*padding-bottom: .25em;*/
  border-bottom: 1px solid gray;
  margin-bottom: 1em;
}
